<template>
  <div>
    <div class="row col-12 page-head">
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb page-head-nav">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'dashboard' }">Home</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link-active :to="{ name: 'locations-cluster' }"
              >Location Cluster</router-link-active
            >
          </li>
        </ol>
      </nav>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="col-12 mx-auto">
          <div class="clearfix mb-4">
            <h2 class="text-capitalize float-left mb-0">
              {{ currentPage }}
            </h2>
          </div>
          <div class="row mb-4">
            <div class="col-md-6">
              <div
                :class="[
                  `card text-center`,
                  activeTab === 'router locations' ? 'bg-primary text-white' : '',
                ]"
                @click="() => handleSetActiveTab('router locations')"
                style="cursor: pointer"
              >
                <div class="p-2">
                  Routers
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div
                :class="[
                  `card text-center`,
                  activeTab === 'kiosk locations' ? 'bg-primary text-white' : '',
                ]"
                @click="() => handleSetActiveTab('kiosk locations')"
                style="cursor: pointer"
              >
                <div class="p-2">
                  Kiosk locations
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
              <!-- insert locations here -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      activeTab: "router locations",
      realms: [],
      currentPage: "Router Location Cluster",
      partnerId: localStorage.getItem("partner_id"),
    };
  },
  methods: {
    handleSetActiveTab(tab) {
      this.activeTab = tab;
      if (this.activeTab == "router locations") {
        this.currentPage = "Router Location Cluster";
      } else if (this.activeTab == "kiosk locations locations") {
        this.currentPage = "kiosk locations Location Cluster";
      }
    },
  },
};
</script>
<style></style>
