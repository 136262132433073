var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row col-12 page-head"},[_c('nav',{attrs:{"aria-label":"breadcrumb","role":"navigation"}},[_c('ol',{staticClass:"breadcrumb page-head-nav"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'dashboard' }}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link-active',{attrs:{"to":{ name: 'locations-cluster' }}},[_vm._v("Location Cluster")])],1)])])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"col-12 mx-auto"},[_c('div',{staticClass:"clearfix mb-4"},[_c('h2',{staticClass:"text-capitalize float-left mb-0"},[_vm._v(" "+_vm._s(_vm.currentPage)+" ")])]),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-md-6"},[_c('div',{class:[
                `card text-center`,
                _vm.activeTab === 'router locations' ? 'bg-primary text-white' : '',
              ],staticStyle:{"cursor":"pointer"},on:{"click":() => _vm.handleSetActiveTab('router locations')}},[_c('div',{staticClass:"p-2"},[_vm._v(" Routers ")])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{class:[
                `card text-center`,
                _vm.activeTab === 'kiosk locations' ? 'bg-primary text-white' : '',
              ],staticStyle:{"cursor":"pointer"},on:{"click":() => _vm.handleSetActiveTab('kiosk locations')}},[_c('div',{staticClass:"p-2"},[_vm._v(" Kiosk locations ")])])])]),_c('div',{staticClass:"col-12"})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }